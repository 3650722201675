import { AccountBalanceWallet } from "@material-ui/icons"
import { Article, CalendarToday, ContentCopy, GraphicEq, NoteAlt, Pix, ShoppingBag } from "@mui/icons-material"
import moment from "moment"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Loading from "../../../components/Loading"
import Title from "../../../components/Title"
import { formatCurrency } from "../../../helpers/formatCurrency"
import { formatStringByMask } from "../../../helpers/formatStringByMask"
import orders from "../../../services/orders"

export default function UserSingleOrder(props) {
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState([])
  const orderID = props.match.params.id
  const orderStatus = [
    {
      "raw": "pending",
      "label": "Pendente",
      color: "#D8C364",
    },
    {
      "raw": "processing",
      "label": "Processando",
      color: "#4E9DCB",
    },
    {
      "raw": "canceled",
      "label": "Cancelado",
      color: "#D86470",
    },
    {
      "raw": "failed",
      "label": "Falhou",
      color: "#CB784E",
    },
    {
      "raw": "expired",
      "label": "Expirado",
      color: "#B1B5C4",
    },
    {
      "raw": "completed",
      "label": "Completo",
      color: "#209869",
    }
  ]
  const orderPayment = [
    {
      raw: "pix",
      label: "Pix",
      icon: <Pix style={{fill: "#209869"}} />,
    },
    {
      raw: "wallet",
      label: "Carteira",
      icon: <AccountBalanceWallet style={{fill: "#209869"}} />,
    },
    {
      raw: "boleto",
      label: "Boleto",
      icon: <Article style={{fill: "#209869"}} />,
    },
  ]
  const userFiels = {
    client: [
      {
        label: "Nome",
        value: `${orderData?.user?.first_name} ${orderData?.user?.last_name}`
      },
      {
        label: "Email",
        value: orderData?.user?.email
      },
      {
        label: "Telefone",
        value: formatStringByMask("(XX) XXXXX-XXXX", orderData?.user?.phone)
      },
      {
        label: "CPF",
        value: formatStringByMask("XXX.XXX.XXX-XX", orderData?.user?.cpf)
      },
    ],
    address: [
      {
        label: "Localidade",
        value: `${orderData?.user?.address?.city} - ${orderData?.user?.address?.state}`
      },
      {
        label: "Logradouro",
        value: orderData?.user?.address?.address
      },
      {
        label: "CEP",
        value: formatStringByMask("XXXXX-XXX", orderData?.user?.address?.zipcode)
      },
    ],
    payment: [
      {
        label: "Tipo da compra",
        value: orderData?.type_purchase === "share" ? "cota" : "crédito"
      },
      {
        label: "Valor",
        value: formatCurrency(orderData?.payment?.amount)
      },
      {
        label: "TID",
        value: (
          <div className="flex items-center gap-1">
            <span className="truncate max-w-[150px]">{orderData?.payment?.tid}</span>
            <ContentCopy onClick={() => {
              navigator.clipboard.writeText(orderData?.payment?.tid)
              toast.success("Código copiado com sucesso!")
            }} style={{cursor: "pointer", fontSize: 18, fill: "#209869", flexShrink: 0}} />
          </div>
        )
      },
      {
        label: "Status",
        value: (
          <div className="flex items-center gap-1">
            <span style={{background: orderStatus?.filter(data => orderData?.payment?.status === data.raw)[0]?.color, width: 10, height: 10}} className='rounded-full' />
            <span>{ orderStatus?.filter(data => orderData?.payment?.status === data.raw)[0]?.label || '-' }</span>
          </div>
        )
      },
      {
        label: "Método pagamento",
        value: (
          <div className="flex items-center gap-2">
            { orderPayment.find(item => orderData?.payment?.payment_channel?.toLowerCase() === item.raw.toLowerCase())?.icon }
            <span>{ orderPayment.find(item => orderData?.payment?.payment_channel?.toLowerCase() === item.raw.toLowerCase())?.label }</span>
          </div>
        )
      },
    ]
  }

  useEffect(() => {
    orders.getOrder(props.match.params.id).then((res) => {
      setOrderData(res.data)
      setLoading(false)
    })
  }, [])

  return (
    <main>
      {loading && <Loading />}
      {!loading && (
        <>
          <Title label={`Detalhes da ordem: ${orderID}`} />
          <div className="mb-20 flex gap-x-4 min-h-[80vh] max-h-[70vh]">
            <div className="gap-3 flex flex-col flex-1 min-w-[50%] bg-white rounded-lg">
              <div className="flex justify-between gap-4">
                <div className="flex flex-col flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                  <h3 className="pb-2 text-lg font-medium border-b border-solid border-neutral-6">Dados do cliente</h3>
                  {userFiels.client.map((field) => (
                    <>
                      <div className="flex items-center gap-1">
                        <span className="text-sm font-medium">{field.label}:</span>
                        <span className="text-sm">{field.value || '-'}</span>
                      </div>
                    </>
                  ))}
                  <h3 className="pb-2 text-lg font-medium border-b border-solid border-neutral-6">Endereço</h3>
                  {userFiels.address.map((field) => (
                    <>
                      <div className="flex items-center gap-1">
                        <span className="text-sm font-medium">{field.label}:</span>
                        <span className="text-sm">{field.value || '-'}</span>
                      </div>
                    </>
                  ))}
                </div>
                <div className="flex flex-col flex-1 gap-2 p-4 rounded-lg bg-neutral-7">
                  <h3 className="pb-2 text-lg font-medium border-b border-solid border-neutral-6">Pagamento</h3>
                  {userFiels.payment.map((field) => (
                    <>
                      <div className="flex items-center gap-1">
                        <span className="text-sm font-medium">{field.label}:</span>
                        <div className="flex items-center gap-1">
                          <div className="text-sm">
                            {field.value || '-'}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2 p-4 rounded-lg bg-neutral-7">
                <h3 className="pb-2 text-lg font-medium border-b border-solid border-neutral-6">Items comprados</h3>
                <div className="pr-4 flex flex-col gap-2 overflow-auto scrollbar-default max-h-[320px]">
                  { orderData?.items?.map((item) => {
                    return (
                      <>
                        <div className="flex flex-col items-center w-full gap-2 p-3 bg-white rounded-lg lg:flex-row">
                          <ShoppingBag />
                          <div className="flex flex-col">
                            <span className="text-base">{item.ref ? `${item.name} ${item.ref}` : item.name} <span className="font-bold">({item.qty}x)</span></span>
                            <span className="text-sm font-medium">{formatCurrency(item.price)}</span>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <aside className="p-4 rounded-lg flex flex-col max-w-[550px] bg-neutral-7 w-full">
              <h3 className="pb-2 text-lg font-medium border-b border-solid border-neutral-6">Histórico</h3>
              <div className="pr-4 flex flex-col-reverse gap-4 overflow-y-auto scrollbar-default max-h-[700px]">
                { orderData?.histories?.map((history) => {
                  return (
                    <>
                      <div className="flex flex-col items-center w-full gap-2 lg:flex-row">
                        <NoteAlt />
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-1">
                            <CalendarToday style={{fill: "#B1B5C4", fontSize: 16}} />
                            {moment(history.created_at, 'DD-MM-YYYY HH:mm').format("DD/MM/YYYY HH:mm")}
                          </div>
                          <span>{history.description}</span>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </aside>
          </div>
        </>
      )
      }
    </main>
  )
}
