import Axios from 'axios'
import { history } from '../helpers/history'

export const publicRequest = Axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })
export const onboardRequest = Axios.create({ baseURL: process.env.REACT_APP_API_ONBOARD_URL })
export const jackpotFileRequest = Axios.create({ baseURL: process.env.REACT_APP_API_JACKPOT_URL })
export const jackpotRequest = Axios.create({ baseURL: process.env.REACT_APP_MSS_JACKPOT_URL })
const api = Axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

const instances = [api, publicRequest, onboardRequest, jackpotFileRequest, jackpotRequest]

export const interceptRequest = () => {
	return instances.forEach(instance => {
		instance.interceptors.request.use((config) => {
			const authUserToken = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'))
			if (authUserToken) config.headers.Authorization = `Bearer ${authUserToken.token}`
			return config
		}, (error) => {
			return Promise.reject(error)
		})
	})
}

export const interceptResponse = () => {
	return instances.forEach(instance => {
		instance.interceptors.response.use(response => {
			return response
		}, error => {
			if (error.response && (error.response.status === 401)) {
				localStorage.removeItem('unindo_sonhos_admin_data')
				history.push('/')
			} else {
				return Promise.reject(error)
			}
		})
	})
}

export default api
