import {CircularProgress} from "@material-ui/core";
import React from "react";
import classNames from 'classnames'
import {Pagination as UIPagination} from '@nextui-org/react'

function Pagination({type, pagination, paginate}) {
    if (pagination && !Object.entries(pagination)?.length > 0 || !pagination) return null;

    const currentPage = type === "new" ? pagination?.current : pagination?.current_page;
    const totalPages = pagination?.total_pages

		console.log(currentPage, pagination)

    return (
			<div className={'my-8'}>
				<UIPagination showControls total={totalPages} initialPage={1} page={currentPage} onChange={page => paginate(page)} className={'self-center'}  />
			</div>
    )
}

export default function Table({rows, withBackground = false, loadingRows = [], loading = false, renderRow, pagination, paginate}) {
    return (
        <div className="flex flex-col items-center">
          <table className="table border border-gray-200 border-b-none">
              <thead className="border-b border-gray-200 bg-gray-50">
              <tr className="">
                  {renderRow().map((row) => {
                      if (window.screen.width < 1700 && row.hiddenResponsive) return null
                      return (
                          <th
                              key={row.headerName}
                              className="border-none px-4 py-3.5 text-gray-500 font-normal text-sm" scope="col">
                              {row.headerName}
                          </th>
                      )
                  })}
              </tr>
              </thead>
              <tbody className={classNames(
                "divide-y relative divide-gray-200",
              )}>
              {loading && (
								<div className={'bg-white/70 z-10 absolute w-full flex flex-col h-full'}>
									<CircularProgress size={32} className="text-primary m-10 self-center"/>
								</div>
              )}
							{rows?.length > 0 ? (
								<>
									{rows.map((row, index) => {
										const isLoading = loadingRows.includes(row.id)

										return (
											<tr key={row?.id || index} className={classNames(
												'relative',
												withBackground && 'bg-white',
											)}>
												<>
													{renderRow(row).map((rowData, colIndex) => {
														if (window.screen.width < 1700 && rowData.hiddenResponsive) return null

														let resultItem;
														if (rowData.field && !rowData.render) {
															resultItem = row[rowData.field]
														}

														if (rowData.render) {
															resultItem = rowData.render(row)
														}

														return (
															<>
																<td
																	key={Math.random()}
																	className={classNames(
																		"align-middle px-4 py-4 text-sm font-medium text-gray-700 border-none reference",
																		rowData.columnClassName,
																	)}
																>
																	{resultItem || '-'}
																	{isLoading && colIndex === 0 && (
																		<div className="absolute flex-1 flex items-center justify-center left-0 top-0 w-full h-full bg-white/75 z-50">
																			<CircularProgress size={24} className="text-primary icon"/>
																		</div>
																	)}
																</td>
															</>
														)
													})}
												</>
											</tr>
										)
									})}
								</>
							) : (
								<>
									{!loading && (<tr key="empty">
											<td colSpan="12" className={classNames('py-5 text-center border-none empty', withBackground && 'bg-white')}>
												<span>Nenhum registro encontrado.</span>
											</td>
										</tr>
									)}
								</>)}
              </tbody>
          </table>
          {rows?.length >= 1 && <Pagination key={pagination} type={pagination?.current ? 'new' : 'old'} pagination={pagination} paginate={paginate}/>}
        </div>
    )
}
