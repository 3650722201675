import React, { useState, useEffect } from 'react';

import { TransitionGroup } from "react-transition-group";
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { history } from '../../../../helpers/history';
import concourseService from '../../../../services/concourse';

import './style.scss';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SendNewGames from '../../../../components/SendNewGames';
import ProcessingGames from './ProcessingGames';
import ReceiptGames from './ReceiptGames';
import Games from './Games';
import Loading from '../../../../components/Loading';
import MusRules from "./MusRules";

const ConcourseSingle = (props) => {
    const _status = [
        { "option": "awaiting", "label": "Aguardando", },
        { "option": "draft", "label": "Rascunho", },
        { "option": "cancelled", "label": "Cancelado", },
        { "option": "production", "label": "Em produção", },
        { "option": "finished", "label": "Encerrado" },
        { "option": "refunded", "label": "Reembolsado" },
    ];

    const _game_type = [
        { "option": "mega-sena", "label": "Mega Sena", },
        { "option": "dupla-sena", "label": "Dupla sena", },
        { "option": "lotomania", "label": "Lotomania", },
        { "option": "quina", "label": "Quina", },
        { "option": "lotofacil", "label": "Lotofácil" },
        { "option": "dia-de-sorte", "label": "Dia de sorte" },
        { "option": "super-sete", "label": "Super sete" },
        { "option": "timemania", "label": "Timemania" },
        { "option": "mais-milionaria", "label": "+Milionária" },
    ]

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("listGames");
    const [concourse, setConcourse] = useState({});

    useEffect(() => {
        loadConcourse()
    }, [])

    const loadConcourse = async () => {
        setLoading(true);

        const _id = props.match.params.id;
        const _concourse = await concourseService.getConcourse(_id);
        setConcourse(_concourse.data);

        setLoading(false);
    }

    function handleTabChange(active) {
        setActiveTab(active)
    }

    const disabledTab = concourse.status !== "awaiting" && concourse.status !== "production"

    return (
        <main id="view">
            {loading && <Loading />}

            <div id="title">
                <span className="back" onClick={event => history.goBack()}><ArrowBackIosIcon /> <span>Voltar</span></span>
                <h1>Concurso:{concourse.number}</h1>
            </div>

            <section id="data">
                <div className="row">
                    <span><strong>Número: </strong>{concourse.number}</span>
                    <span><strong>Data do sorteio: </strong>{moment(concourse.date_draw, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}</span>
                    <span><strong>Data de início: </strong>{moment(concourse.start_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}</span>
                    <span><strong>Data de término: </strong>{moment(concourse.end_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm")}</span>
                    <span><strong>Prêmio estimado: </strong>R${concourse.premium_estimate}</span>
                    <span><strong>Status: </strong>{concourse.status ? _status.filter((item) => item.option === concourse.status)[0].label : ''}</span>
                    <span><strong>Tipo de jogo: </strong>{concourse.type_game ? _game_type.filter((item) => item.option === concourse.type_game.slug)[0]?.label : ''}</span>
                </div>
            </section>
            {!loading &&
                <Tabs defaultActiveKey="gameList" id="jackpot-tabs" activeKey={activeTab} onSelect={handleTabChange}>
                    <Tab eventKey="uploadGames" title="Envio de jogos" disabled={disabledTab}>
                        <SendNewGames activeTab={activeTab} setActiveTab={setActiveTab} concourse={concourse} />
                    </Tab>
                    <Tab eventKey="processingGames" title="Processamento de jogos" disabled={disabledTab}>
                        <ProcessingGames activeTab={activeTab} concourse={concourse} />
                    </Tab>
                    <Tab eventKey="listGames" title="Jogos">
                        <Games activeTab={activeTab} concourse={concourse} />
                    </Tab>
                    <Tab eventKey="receiptGames" title="Comprovantes">
                        <ReceiptGames activeTab={activeTab} concourse={concourse} />
                    </Tab>
                    <Tab eventKey="musRules" title="Regras">
                        <MusRules activeTab={activeTab} concourse={concourse} />
                    </Tab>
                </Tabs>
            }
        </main>
    );
}

export default ConcourseSingle;
