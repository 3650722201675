import React, { useEffect, useState } from 'react';

import Title from '../../../components/Title';
import Loading from '../../../components/Loading';
import orderService from '../../../services/orders';
import moment from 'moment';
import { AccountBalanceWallet, ArrowBackIos, ArrowForwardIos, OpenInNew } from '@material-ui/icons';
import { ArrowDownward, ArrowUpward, Article, ContentCopy, FilterAltOff, Pix } from '@mui/icons-material'
import Button from '../../../components/Button';
import { CheckPicker, DateRangePicker, Drawer, Input, MaskedInput } from 'rsuite';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { history } from '../../../helpers/history';
import { toast } from 'react-toastify';
import TagItem from '../../../components/TagItem';
import Table from '../../../components/Table';
import userService from '../../../services/users'
import { formatStringByMask } from '../../../helpers/formatStringByMask'

export function handleSingleOrder(id) {
	history.push(`/financeiro/compras/${id}`)
}

const walletType = [
	{
		"value": "default",
		"label": "Digital",
	},
	{
		"value": "mus",
		"label": "MUS",
	},
]


export default function Wallets(pageProps) {
	const urlParams = new URLSearchParams(pageProps?.location?.search)
  const [walletFilter, setWalletFilter] = useState()
  const [periodFilter, setPeriodFilter] = useState()
  const [cpfFilter, setCpfFilter] = useState()
  const [emailFilter, setEmailFilter] = useState()
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
	const tableService = userService.getWallets

  useEffect(() => {
	  getData()
  }, []);

  const getData = async () => {
    setLoading(true);

    const data = await tableService({
      page: 1,
    });
    if (data) {
      setData(data.result);
      setPagination(data.pagination);
    }
    setLoading(false);
  }

  const paginate = async (page) => {
    setLoading(true);
    const payload = {
      ...(walletFilter?.length > 0 && { wallet: encodeURI(walletFilter) }),
      ...(periodFilter?.length > 0 && { range_date: encodeURI([moment(periodFilter[0]).format('YYYY-MM-DD'), moment(periodFilter[1]).format('YYYY-MM-DD')]) }),
      ...(cpfFilter && { customer_cpf: cpfFilter.replaceAll(".", "").replace("-", "") }),
	    ...(emailFilter && { customer_email: emailFilter }),
      page,
    }

    await tableService(payload).then(data => {
      if (!data) return setLoading(false);
      setData(data.result);
      setPagination(data.pagination);
    }).finally(() => setLoading(false));
  }

  const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

  const predefinedRanges = [
    {
      label: 'Hoje',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Ontem',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'Essa semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Últimos 7 dias',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Últimos 30 dias',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'Esse mês',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Mês passado',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
  ];

  function handleSubmitFilters(e, reset = null) {
    e && e.preventDefault()
    const payload = {
	    ...(walletFilter?.length > 0 && { type: encodeURI(walletFilter) }),
	    ...(periodFilter?.length > 0 && { range_date: encodeURI([moment(periodFilter[0]).format('YYYY-MM-DD'), moment(periodFilter[1]).format('YYYY-MM-DD')]) }),
	    ...(cpfFilter && { customer_cpf: cpfFilter.replaceAll(".", "").replace("-", "") }),
	    ...(emailFilter && { customer_email: emailFilter }),
      page: 1,
    }
    setLoading(true);
    setOpenFilters(false);
	  tableService(reset ? null : payload).then((data) => {
      setData(data.result);
      setPagination(data.pagination);
    }).finally(() => setLoading(false))
  }

  const filterCount = () => {
    const count = [
      walletFilter?.length > 0,
      periodFilter?.length > 0,
      cpfFilter,
      emailFilter,
    ].filter(Boolean).length;
    return count > 0 ? count : 0;
  }

  async function cleanFilters(e) {
    await setWalletFilter([])
    await setPeriodFilter([])
    await setCpfFilter("")
    await setEmailFilter("")
    await filterCount()
    handleSubmitFilters(null, true)
  }

  return (
    <main>
      <Title label="Carteiras" />
      <Drawer size='lg' open={openFilters} onClose={() => setOpenFilters(false)}>
        <Drawer.Header>
          <Drawer.Title>Filtros</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 20 }}>
	        <form className="grid grid-cols-2 gap-4">
		        <div>
			        <label htmlFor="input-cpf" className="text-sm font-medium">CPF</label>
			        <MaskedInput id="input-cpf" value={cpfFilter} onChange={setCpfFilter} placeholder="CPF do cliente" mask={cpfMask} className="w-full"/>
		        </div>
		        <div>
			        <label htmlFor="input-email" className="text-sm font-medium">Email</label>
			        <Input id="input-email" value={emailFilter} onChange={setEmailFilter} placeholder="Email do cliente" type="email" className="w-full"/>
		        </div>
		        <div>
			        <label htmlFor="input-period" className="text-sm font-medium">Período</label>
			        <DateRangePicker
				        placement="auto"
				        id="input-period"
				        value={periodFilter}
				        onChange={setPeriodFilter}
				        ranges={predefinedRanges}
				        placeholder="Escolha um período"
				        className="w-full"
			        />
		        </div>
		        <div>
			        <label htmlFor="input-status" className="text-sm font-medium">Carteira</label>
			        <CheckPicker autoComplete="false" id="input-status" value={walletFilter} onChange={setWalletFilter} placeholder="Escolha um status" searchable={false} sticky data={walletType} className="w-full"/>
		        </div>
		        <div className="flex items-center col-span-2">
			        <Button
				        type="submit"
				        color="green"
				        label="Aplicar"
				        action={(e) => handleSubmitFilters(e)}
			        />
		        </div>
	        </form>
        </Drawer.Body>
      </Drawer>

	    <div id="infos" className="flex justify-end w-full mb-4">
		    <span>Total de <strong>{pagination?.total}</strong> registros encontrados.</span>
	    </div>

	    <div className="flex items-center justify-end w-full gap-2 mb-4">
		    {filterCount() > 0 && (<FilterAltOff onClick={cleanFilters} style={{ cursor: "pointer", fill: "#209869" }} />
        )}
        <Button
          small
          width="202px"
          color="green"
          action={setOpenFilters}
          label={`Aplicar filtros ${filterCount() > 0 ? `(${filterCount()})` : ''}`}
          disabled={loading}
        />
      </div>

      <Table
        rows={data}
        renderRow={(row) => ([
	        {
		        headerName: "Usuário",
		        render: () => [row?.holder?.first_name, row?.holder?.last_name].join(' ')
	        },
	        {
		        headerName: "Email",
		        render: () => row?.holder?.email
	        },
	        {
		        headerName: "CPF",
		        render: () => (
			        formatStringByMask('XXX.XXX.XXX-XX', row?.holder?.cpf)
		        )
	        },
	        {
		        headerName: "Carteira",
		        render: () => row?.slug === 'default' ? 'Digital' : 'MUS'
	        },
	        {
						headerName: 'Saldo',
		        render: () => row?.slug === 'default'
			        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.balanceFloat)
		          : `${row.balance} MUS`
	        },
	        { headerName: 'Data de criação', render: () => moment(row.created_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') },
        ])}
        pagination={pagination}
        paginate={paginate}
        loading={loading}
      />
    </main>
  )
}
