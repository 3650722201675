import React, { Fragment } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { history } from './helpers/history'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as appActions from './store/app/actions'

import PrivateRoute from './components/PrivateRoute'
import Menu from './components/Menu'
import Footer from './components/Footer'

import Concourses from './pages/Campaigns/Concourses'
import ConcoursesNew from './pages/Campaigns/Concourses/New'
import ConcoursesEdit from './pages/Campaigns/Concourses/Edit'
import ConcoursesSingle from './pages/Campaigns/Concourses/View'

import Jackpots from './pages/Campaigns/Jackpots'
import JackpotsNew from './pages/Campaigns/Jackpots/New'
import JackpotsSingle from './pages/Campaigns/Jackpots/View'

import Banners from './pages/Campaigns/Banners'
import BannersNew from './pages/Campaigns/Banners/New'
import BannersEdit from './pages/Campaigns/Banners/Edit'
import Withdraw from './pages/Financial/Withdraw'

import Users from './pages/Configurations/Users'
import User from './pages/Configurations/Users/Single'
import UserValidations from './pages/Configurations/Users/Single/Validations'

import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Depositions from './pages/Configurations/Depositions'
import Terms from './pages/Configurations/Terms'
import TermsNew from './pages/Configurations/TermsNew'
import TermsEdit from "./pages/Configurations/TermsEdit";
import Mus from "./pages/Mus";

import Rules from "./pages/Rules";
import RulesNew from "./pages/Rules/new";
import RulesConfig from "./pages/Rules/config";
import UserOrders from './pages/Financial/Orders'
import UserSingleOrder from './pages/Financial/Orders/single'
import Transactions from './pages/Financial/Transactions'
import Wallets from './pages/Financial/Wallets'

const Routes = (props) => {
	return (<Router history={history}>
			<Route render={({ location }) => {
				const hiddenMenuRoutes = ['/login', '/', '/404']
				const show = hiddenMenuRoutes.indexOf(location.pathname) < 0
				return (<Fragment key={location}>
						{show ? <Menu/> : null}
						<TransitionGroup className={`${show ? 'content' : ''} ${props.appData.menu && show ? 'menu-opened' : ''}`}>
							<CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames={'fade'}>
								<Switch>
									<Route path="/" component={Login} exact/>
									<Redirect from={'/login'} to={'/'}/>
									<PrivateRoute path="/dashboard" component={Concourses}/>

									<PrivateRoute whiteListGroup="usersPage" path="/usuarios"                                      component={ Users }       exact />
									<PrivateRoute whiteListGroup="usersPage" path="/usuarios/:userID"                              component={ User }        exact />

									<PrivateRoute whiteListGroup="usersPage" path="/usuarios/:userID/validacoes"                   component={ UserValidations } exact />

									<PrivateRoute whiteListGroup="depositionsPage" path="/configuracoes/depoimentos"               component={ Depositions } exact />

									<PrivateRoute whiteListGroup="termsPage" path="/configuracoes/termos"                          component={ Terms }       exact />
									<PrivateRoute whiteListGroup="termsPage" path="/configuracoes/termos/novo"                     component={ TermsNew }  exact />
									<PrivateRoute whiteListGroup="termsPage" path="/configuracoes/termos/:id"                      component={ TermsEdit } exact />

									<PrivateRoute path="/campanhas/concourses" component={Concourses} exact/>
									<PrivateRoute path="/campanhas/concourses/editar/:id" component={ConcoursesEdit}/>
									<PrivateRoute path="/campanhas/concourses/novo" component={ConcoursesNew}/>
									<PrivateRoute path="/campanhas/concourses/:id" component={ConcoursesSingle} exact/>

                                    <PrivateRoute path="/mus" component={Mus} exact/>

                                    <PrivateRoute path="/rules" component={Rules} exact/>
                                    <PrivateRoute path="/rules/new" component={RulesNew} exact/>
                                    <PrivateRoute path="/rules/config" component={RulesConfig} exact/>

									<PrivateRoute path="/campanhas/concourses/boloes" component={Jackpots} exact/>
									<PrivateRoute path="/campanhas/concourses/:concourse/boloes/novo" component={JackpotsNew}/>
									<PrivateRoute path="/campanhas/concourses/:concourse/boloes/:id" component={JackpotsSingle}/>

									<PrivateRoute path="/campanhas/banners" component={Banners} exact/>
									<PrivateRoute path="/campanhas/banners/novo" component={BannersNew}/>
									<PrivateRoute path="/campanhas/banners/editar/:id" component={BannersEdit}/>

									{/*<PrivateRoute path="/financeiro/boletos"      component={ Barcode }        />*/}
									<PrivateRoute path="/financeiro/compras"      component={ UserOrders } exact     />
									<PrivateRoute path="/financeiro/compras/:id"  component={ UserSingleOrder }  />
									<PrivateRoute path="/financeiro/transacoes"  component={ Transactions } exact  />
									<PrivateRoute path="/financeiro/carteiras"  component={ Wallets } exact  />
									{/*<PrivateRoute path="/financeiro/pix"          component={ PIX }            />*/}
									{/*<PrivateRoute path="/financeiro/premios"      component={ Prizes }         />*/}
									<PrivateRoute path="/financeiro/saques" component={Withdraw}/>
									<PrivateRoute path="/financeiro/saques/:page" component={Withdraw}/>
									<Route path="/404" component={NotFound} exact />
									<Redirect from="*" to={"/404"} />

								</Switch>
							</CSSTransition>
							{show ? <Footer/> : null}
						</TransitionGroup>
					</Fragment>)
			}}/>
		</Router>)
}

const mapStateToProps = state => ({ appData: state.appReducer })
const mapDispatchToProps = dispatch => ({ appActions: bindActionCreators(appActions, dispatch) })
export default connect(mapStateToProps, mapDispatchToProps)(Routes)
