import api, { onboardRequest } from './axiosInstances'

const userService = {
  async getUsers(payload = {}, customFilter = {}) {
    try {
      const payloadFilters = payload && Object.entries(payload)
      .filter(([key, value]) => {
        const currentValue = value?.formatted ? value.formatted : value.raw
        if (!currentValue || currentValue === "" || currentValue.length === 0) return false

        return true
      })
      .map(([key, value]) => {
        const currentValue = value?.formatted ? value.formatted : value.raw
        return `${key}:${typeof currentValue === "object" ? currentValue.join(',') : currentValue}`
      })

      const params = {
        orderBy: "created_at",
        sortedBy: "desc",
        ...(payloadFilters?.length >= 1 && {
          search: payloadFilters.join(';'),
        }),
        ...(payloadFilters?.length >= 2 && {
          searchJoin: "and",
        }),
        ...customFilter,
      }

      const { data } = await onboardRequest.get('/admin/users', { params });
      return data;
    }
    catch(error) {
      console.log(error)
      return false;
    }
  },
	async getWallets(payload) {
		try {
			const { data } = await api.get("/admin/wallets", {
				params: {
					order_by: 'created_at',
					sort_by: 'desc',
					per_page: 20,
					...payload,
				},
			})
			return data;
		}
		catch(error) {
			return false;
		}
	},
	async revokeUserInactivation(id) {
		try {
			await onboardRequest.patch(`/admin/restore/${id}`);
			return true
		}
		catch(error) {
			return false;
		}
	},
}

export default userService;
