import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NavLink } from "react-router-dom";
import { history } from '../../helpers/history';

import MenuIcon  from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';

import './style.scss';

import * as accountActions from '../../store/account/actions';
import * as appActions     from '../../store/app/actions';

import logo from '../../assets/images/logotipo-unindo-sonhos.png';
import { canWhiteList, checkWhiteList } from '../PrivateRoute'

const Menu = (props) => {

    const logout = () => {
        localStorage.removeItem('unindo_sonhos_admin_data');
        history.push('/');
    }

    return (
        <section id="menu" className={ props.appData.menu ? 'active' : '' }>

            {
                props.appData.menu
                    ? <span className="toogle-button"><ArrowBackIosIcon className="toggle-menu close" onClick={ props.appActions.toggleMenu } /></span>
                    : <span className="toogle-button"><MenuIcon         className="toggle-menu"       onClick={ props.appActions.toggleMenu } /></span>
            }

            <div className="box">

                <figure id="logo">
                    <img src={ logo } alt="Unindo Sonhos"/>
                </figure>

                <hr/>

                <nav>
                    <ul>
	                    <li>
		                    <GolfCourseIcon/><span>Campanhas</span>
		                    <ul className="submenu">
			                    {canWhiteList('bannersPage') && (
                                    <li>
                                        <ArrowForwardIosIcon/>
                                        <NavLink to="/campanhas/banners" activeClassName="active">Banners</NavLink>
                                    </li>
			                    )}
			                    {/* <li><ArrowForwardIosIcon/><NavLink to="/campanhas/boloes">Bolões</NavLink></li> */}
			                    <li><ArrowForwardIosIcon/><NavLink to="/campanhas/concourses">Concursos</NavLink></li>
			                    <li><ArrowForwardIosIcon/><NavLink to="/rules" exact>MUS</NavLink></li>
		                    </ul>
	                    </li>
	                    <li>
		                    <AccountBalanceIcon/>
                            <span>Financeiro</span>
	                        <ul className="submenu">
		                        <li><ArrowForwardIosIcon/><NavLink to="/financeiro/saques">Saques</NavLink></li>
		                        <li><ArrowForwardIosIcon/><NavLink to="/mus">Liberações MUS</NavLink></li>
		                        {/*<li><ArrowForwardIosIcon/><NavLink to="/financeiro/boletos">Boletos</NavLink></li>*/}
		                        {/*<li><ArrowForwardIosIcon/><NavLink to="/financeiro/pix">PIX</NavLink></li>*/}
		                        {/*<li><ArrowForwardIosIcon/><NavLink to="/financeiro/premios">Prêmios</NavLink></li>*/}
	                        </ul>
                        </li>
	                    <li>
		                    <AccountBalanceIcon />
                            <span>Clientes</span>
		                    <ul className="submenu">
			                    <li><ArrowForwardIosIcon/><NavLink to="/usuarios">Usuários</NavLink></li>
			                    <li><ArrowForwardIosIcon/><NavLink to="/financeiro/compras">Ordens de compras</NavLink></li>
			                    <li><ArrowForwardIosIcon/><NavLink to="/financeiro/transacoes">Transações</NavLink></li>
		                    </ul>
	                    </li>
	                    <li>
		                    <AccountBalanceIcon/><span>Configurações Gerais</span>
		                    <ul className="submenu">
			                    <li><ArrowForwardIosIcon/><NavLink to="/configuracoes/termos">Termos</NavLink></li>
			                    <li><ArrowForwardIosIcon/><NavLink to="/rules/config" exact>Configurações MUS</NavLink></li>
			                    {/*<li><ArrowForwardIosIcon/><NavLink to="/configuracoes/depoimentos">Depoimentos</NavLink></li>*/}
		                    </ul>
	                    </li>
	                    <li><ExitToAppIcon/><span className="exit" onClick={logout}>Sair</span></li>
                    </ul>
                </nav>
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        userData: state.accountReducer.userData,
        appData: state.appReducer
    }
};
const mapDispatchToProps = dispatch => {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch)
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
