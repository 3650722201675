import React from 'react';

import { history }      from '../../helpers/history';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import './style.scss';

const Title = (props) => {

    return (<div id="title">
                <span className="back" onClick={ event => history.goBack() }><ArrowBackIosIcon /> <span>Voltar</span></span>
                <h1>{ props.label }</h1>
            </div>);
}

export default Title;