import React               from 'react';
import { Route, Redirect } from 'react-router-dom';

export function canWhiteList(whiteListGroup) {
	const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

	const whiteListUsers = {
		usersPage: [
			"chellepalmeira@hotmail.com",
			"nathaliamouradesouza@gmail.com",
			"guimaraesslara@gmail.com",
			"hillaryximenes@gmail.com",
			"vitoriabrunkhorst@hotmail.com",

			"jefferson@plastikastudio.com.br",
			"guilherme@plastikastudio.com.br",
            "brunabizotto18@hotmail.com",
		],
		bannersPage: [
			"vitoriabrunkhorst@hotmail.com",
			"guimaraesslara@gmail.com",
			"guilherme@plastikastudio.com.br",
            "brunabizotto18@hotmail.com",
		],
		depositionsPage: [
			"nathaliamouradesouza@gmail.com",
			"guilherme@plastikastudio.com.br",
            "brunabizotto18@hotmail.com",
		],
		termsPage: [
          'guimaraesslara@gmail.com',
          'nathaliamouradesouza@gmail.com',
          'guilherme@plastikastudio.com.br',
          'jefferson@plastikastudio.com.br', 
          "brunabizotto18@hotmail.com",
		],
	}

	return storage && whiteListUsers[whiteListGroup]?.includes(storage.user.email)
}

const redirect = (props) => {
	const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

	if(!storage && props.userData)
		props.reset();

	return (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
}

const PrivateMenu = ({ component: Component, ...rest }) => {
		const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

    return (
        <Route { ...rest } render={
            (props) => {
		            if (rest.whiteListGroup && !canWhiteList(rest.whiteListGroup)) {
			            return <Redirect to={{ pathname: '/404' }} />
		            }

                return (storage && storage.token)
                    ? (<Component {...props} />)
                    : redirect(rest);
            }} />
    );
}

export default PrivateMenu;
