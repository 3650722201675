import React    from 'react';
import { createRoot } from 'react-dom/client';

import { composeWithDevTools }                           from 'redux-devtools-extension';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider }                                      from 'react-redux';
import thunk                                             from 'redux-thunk';

import * as reducers                          from './store/reducers';
import App from './App';
import { interceptRequest, interceptResponse } from './services/axiosInstances'

const store = createStore(combineReducers(reducers), composeWithDevTools(applyMiddleware(thunk)));

interceptRequest();
interceptResponse();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<Provider store={ store }>
		<App />
	</Provider>
);
