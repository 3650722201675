import { useEffect, useState } from 'react';

import Title from '../../../components/Title';
import Loading from '../../../components/Loading';
import orderService from '../../../services/orders';
import moment from 'moment';
import { AccountBalanceWallet, ArrowBackIos, ArrowForwardIos, OpenInNew } from '@material-ui/icons';
import { ArrowUpward, Article, ContentCopy, FilterAltOff, Pix } from '@mui/icons-material'
import Button from '../../../components/Button';
import { CheckPicker, DateRangePicker, Drawer, Input, MaskedInput } from 'rsuite';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { history } from '../../../helpers/history';
import { toast } from 'react-toastify';
import TagItem from '../../../components/TagItem';
import Table from '../../../components/Table';

export function handleSingleOrder(id) {
	history.push(`/financeiro/compras/${id}`)
}

export const orderStatus = [
	{
		"raw": "pending",
		"label": "Pendente",
		color: "#D8C364",
	},
	{
		"raw": "processing",
		"label": "Processando",
		color: "#4E9DCB",
	},
	{
		"raw": "canceled",
		"label": "Cancelado",
		color: "#D86470",
	},
	{
		"raw": "failed",
		"label": "Falhou",
		color: "#CB784E",
	},
	{
		"raw": "expired",
		"label": "Expirado",
		color: "#B1B5C4",
	},
	{
		"raw": "completed",
		"label": "Completo",
		color: "#209869",
	}
]

export const orderPayment = [
	{
		"raw": "Pix",
		"label": "Pix",
		icon: <Pix style={{ fill: "#209869" }} />,
	},
	{
		"raw": "wallet",
		"label": "Carteira",
		icon: <AccountBalanceWallet style={{ fill: "#209869" }} />,
	},
	{
		"raw": "Boleto",
		"label": "Boleto",
		icon: <Article style={{ fill: "#209869" }} />,
	},
]

export default function Transactions(pageProps) {
	const urlParams = new URLSearchParams(pageProps?.location?.search)
  const [statusFilter, setStatusFilter] = useState()
  const [paymentOptionsFilter, setPaymentOptionsFilter] = useState()
  const [periodFilter, setPeriodFilter] = useState()
  const [transactionCodeFilter, setTransactionCodeFilter] = useState()
  const [emailFilter, setEmailFilter] = useState()
  const [cpfFilter, setCpfFilter] = useState()
  const [nameFilter, setNameFilter] = useState()
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  useEffect(() => {
	  getAllOrders()
  }, []);

  const getAllOrders = async () => {
    setLoading(true);

		if (urlParams.get("cpf")) {
			setCpfFilter(urlParams.get("cpf"))
		}

    const data = await orderService.getOrders({
      page: 1,
	    ...(urlParams.get("cpf") && ({ customer_cpf: urlParams.get("cpf") })),
    });
    if (data) {
      setOrders(data.data.result);
      setPagination(data.data.pagination);
    }
    setLoading(false);
  }

  const paginate = async (page) => {
    setLoading(true);
    const payload = {
      ...(statusFilter?.length > 0 && { status: encodeURI(statusFilter) }),
      ...(paymentOptionsFilter?.length > 0 && { payment_channel: encodeURI(paymentOptionsFilter) }),
      ...(periodFilter?.length > 0 && { range_date: encodeURI([moment(periodFilter[0]).format('YYYY-MM-DD'), moment(periodFilter[1]).format('YYYY-MM-DD')]) }),
      ...(transactionCodeFilter && { payment_tid: transactionCodeFilter }),
      ...(emailFilter && { customer_email: emailFilter }),
      ...(cpfFilter && { customer_cpf: cpfFilter }),
      ...(nameFilter && { customer_name: nameFilter }),
      page,
    }

    await orderService.getOrders(payload).then(data => {
      if (!data) return setLoading(false);
      setOrders(data.data.result);
      setPagination(data.data.pagination);
    }).finally(() => setLoading(false));
  }

  const statusOptions = orderStatus.map(item => ({ label: item.label, value: item.raw }));
  const paymentOptions = orderPayment.map(item => ({ label: item.label, value: item.raw }));
  const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

  const predefinedRanges = [
    {
      label: 'Hoje',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Ontem',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'Essa semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Últimos 7 dias',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Últimos 30 dias',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'Esse mês',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Mês passado',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
  ];

  function handleSubmitFilters(e, reset = null) {
    e && e.preventDefault()
    const payload = {
      ...(statusFilter?.length > 0 && { status: encodeURI(statusFilter) }),
      ...(paymentOptionsFilter?.length > 0 && { payment_channel: encodeURI(paymentOptionsFilter) }),
      ...(periodFilter?.length > 0 && { range_date: encodeURI([moment(periodFilter[0]).format('YYYY-MM-DD'), moment(periodFilter[1]).format('YYYY-MM-DD')]) }),
      ...(transactionCodeFilter && { payment_tid: transactionCodeFilter }),
      ...(emailFilter && { customer_email: emailFilter }),
      ...(cpfFilter && { customer_cpf: cpfFilter.replaceAll(".", "").replace("-", "") }),
      ...(nameFilter && { customer_name: nameFilter }),
      page: 1,
    }
    setLoading(true);
    setOpenFilters(false);
    orderService.getOrders(reset ? null : payload).then((data) => {
      setOrders(data.data.result);
      setPagination(data.data.pagination);
    }).finally(() => setLoading(false))
  }

  const filterCount = () => {
    const count = [
      statusFilter?.length > 0,
      paymentOptionsFilter?.length > 0,
      periodFilter?.length > 0,
      transactionCodeFilter,
      emailFilter,
      cpfFilter,
      nameFilter,
    ].filter(Boolean).length;
    return count > 0 ? count : 0;
  }

  async function cleanFilters(e) {
    await setStatusFilter([])
    await setPaymentOptionsFilter([])
    await setPeriodFilter([])
    await setTransactionCodeFilter("")
    await setEmailFilter("")
    await setCpfFilter("")
    await setNameFilter("")
    await filterCount()
    handleSubmitFilters(null, true)
  }

  return (
    <main>
      <Title label="Ordens de compras" />
      <Drawer size='lg' open={openFilters} onClose={() => setOpenFilters(false)}>
        <Drawer.Header>
          <Drawer.Title>Filtros</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 20 }}>
          <form className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="input-status" className="text-sm font-medium">Status</label>
              <CheckPicker autoComplete="false" id="input-status" value={statusFilter} onChange={setStatusFilter} placeholder="Escolha um status" searchable={false} sticky data={statusOptions} className="w-full" />
            </div>
            <div>
              <label htmlFor="input-payment" className="text-sm font-medium">Método pagamento</label>
              <CheckPicker id="input-payment" value={paymentOptionsFilter} onChange={setPaymentOptionsFilter} placeholder="Escolha um método de pagamento" searchable={false} sticky data={paymentOptions} className="w-full" />
            </div>
            <div>
              <label htmlFor="input-period" className="text-sm font-medium">Período de compra</label>
              <DateRangePicker
	              id="input-period"
	              placement="auto"
                value={periodFilter}
                onChange={setPeriodFilter}
                ranges={predefinedRanges}
                placeholder="Escolha um período"
                className='w-full'
              />
            </div>
            <div>
              <label htmlFor="input-transaction" className="text-sm font-medium">Código da transação</label>
              <Input id="input-transaction" value={transactionCodeFilter} onChange={setTransactionCodeFilter} placeholder="Código da transação" className="w-full" />
            </div>
            <div>
              <label htmlFor="input-email" className="text-sm font-medium">Email</label>
              <Input id="input-email" value={emailFilter} onChange={setEmailFilter} placeholder="Email do cliente" type='email' className="w-full" />
            </div>
            <div>
              <label htmlFor="input-cpf" className="text-sm font-medium">CPF</label>
              <MaskedInput id="input-cpf" value={cpfFilter} onChange={setCpfFilter} placeholder="CPF do cliente" mask={cpfMask} className="w-full" />
            </div>
            <div>
              <label htmlFor="input-name" className="text-sm font-medium">Nome</label>
              <Input id="input-name" value={nameFilter} onChange={setNameFilter} placeholder="Nome do cliente" className="w-full" />
            </div>
            <div className='flex items-center col-span-2'>
              <Button
                type="submit"
                color="green"
                label="Aplicar"
                action={(e) => handleSubmitFilters(e)}
              />
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      <div id="infos" className='flex justify-end w-full mb-4'>
        <span>Total de <strong>{pagination?.total}</strong> ordens encontradas.</span>
      </div>

      <div className='flex items-center justify-end w-full gap-2 mb-4'>
        {filterCount() > 0 && (
          <FilterAltOff onClick={cleanFilters} style={{ cursor: "pointer", fill: "#209869" }} />
        )}
        <Button
          small
          width="202px"
          color="green"
          action={setOpenFilters}
          label={`Aplicar filtros ${filterCount() > 0 ? `(${filterCount()})` : ''}`}
          disabled={loading}
        />
      </div>

      <Table
        rows={orders}
        renderRow={(row) => [
          { headerName: "Ordem", field: "id" },
          {
            headerName: "Cód.da transação", render: () => <div className="flex items-center gap-1">
              <div style={{ width: 100 }} className='truncate' title={row.payment_tid || '-'}>
                {row.payment_tid || '-'}
              </div>
              {row.payment_tid && <span className='flex-shrink-0 cursor-pointer'>
                <ContentCopy onClick={() => {
                  navigator.clipboard.writeText(row.payment_tid)
                  toast.success("Código copiado com sucesso!")
                }} style={{ fontSize: 18, fill: "#209869" }} />
              </span>}
            </div>
          },
          {
            headerName: "Status", render: () => {
              const status = orderStatus?.filter(data => row.status === data.raw)[0]
              return <TagItem item={{ color: status?.color, label: status?.label }} />
            }
          },
          {
            headerName: "Método pagamento", render: () => {
              const paymentItem = orderPayment?.filter(data => row.payment_channel === data.raw)[0]
              return (
                <div className="flex items-center gap-2">
                  {paymentItem?.icon}
                  <span>{paymentItem?.label || '-'}</span>
                </div>
              )
            }
          },
          { headerName: "Valor", render: () => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount) },
          { headerName: "Nome do cliente", render: () => row.user_first_name + " " + row.user_last_name },
          { headerName: "Email do cliente", render: () => row.user_email },
          { headerName: "CPF do cliente", render: () => row.user_cpf },
          { headerName: "Data", render: () => moment(row.created_at, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm") },
          {
            headerName: "Ações", render: () =>
              <OpenInNew onClick={() => handleSingleOrder(row.id)} style={{ cursor: "pointer", fill: "#209869" }} />
          },
        ]}
        pagination={pagination}
        paginate={paginate}
        loading={loading}
      />
    </main>
  )
}
